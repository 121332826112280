// Generated by Framer (d9af232)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lBGcGPU4C"];

const serializationHash = "framer-ctcVY"

const variantClassNames = {lBGcGPU4C: "framer-v-gagehq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, text, width, ...props}) => { return {...props, Cz0Dss7rq: text ?? props.Cz0Dss7rq ?? "Smooth Process"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Cz0Dss7rq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "lBGcGPU4C", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-gagehq", className, classNames)} data-border data-framer-name={"Process Ticker"} layoutDependency={layoutDependency} layoutId={"lBGcGPU4C"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-b43d5421-21cd-4fc2-975a-9a99315a0fcc, rgb(19, 24, 57))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-64302b65-26bc-4e69-ae4d-c9415e9b76b9, rgb(0, 4, 31))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UmV0aGluayBTYW5zLTUwMA==", "--framer-font-family": "\"Rethink Sans\", \"Rethink Sans Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "500", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-41c70247-d307-4878-8d56-eae07ab025d6, rgb(167, 173, 190)))"}}>Smooth Process</motion.p></React.Fragment>} className={"framer-yqjowp"} data-framer-name={"Text"} fonts={["GF;Rethink Sans-500"]} layoutDependency={layoutDependency} layoutId={"CcelCLykA"} style={{"--extracted-r6o4lv": "var(--token-41c70247-d307-4878-8d56-eae07ab025d6, rgb(167, 173, 190))", "--framer-paragraph-spacing": "0px"}} text={Cz0Dss7rq} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ctcVY.framer-lwi5iq, .framer-ctcVY .framer-lwi5iq { display: block; }", ".framer-ctcVY.framer-gagehq { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 8px 12px 8px 12px; position: relative; width: min-content; }", ".framer-ctcVY .framer-yqjowp { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ctcVY.framer-gagehq { gap: 0px; } .framer-ctcVY.framer-gagehq > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ctcVY.framer-gagehq > :first-child { margin-left: 0px; } .framer-ctcVY.framer-gagehq > :last-child { margin-right: 0px; } }", ".framer-ctcVY[data-border=\"true\"]::after, .framer-ctcVY [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 43
 * @framerIntrinsicWidth 158
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Cz0Dss7rq":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerpVvnP38I4: React.ComponentType<Props> = withCSS(Component, css, "framer-ctcVY") as typeof Component;
export default FramerpVvnP38I4;

FramerpVvnP38I4.displayName = "Helper/ Process Ticker";

FramerpVvnP38I4.defaultProps = {height: 43, width: 158};

addPropertyControls(FramerpVvnP38I4, {Cz0Dss7rq: {defaultValue: "Smooth Process", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FramerpVvnP38I4, [{explicitInter: true, fonts: [{family: "Rethink Sans", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/rethinksans/v4/AMODz4SDuXOMCPfdoglY9JQuWHBGG0X45DmqklNRCEvmma-aua4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})